import React, { useState, useRef } from "react";
import {
  Container,
  Grid,
  Button,
  Box,
  TextField,
  Input,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Tab from "@mui/material/Tab";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import DownloadIcon from "@mui/icons-material/Download";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Carousel from "react-material-ui-carousel"; // Make sure this is installed
import {
  BUCKET_NAME,
  GET_ANALYZED_CLIENT_DATA_FILES,
  getFullApiUrl,
  LOAD_SCREENSHOT,
} from "../../../utils/apiPath";
import { LAMBDA_FUNCTION_TO_GET_FILTERED_IDENTIFIY_PARAGRAPH } from "../../../utils/lambdaFunctionPath";

const PreviousClassifiedDataWithScreenshot = ({ campaignId }) => {
  const [value, setValue] = useState("1");
  const [loading, setLoading] = useState(false);
  const [filterSimilarityScore, setFilterSimilarityScore] = useState({
    from: 0,
    to: 100,
  });
  const [showCarousel, setShowCarousel] = useState(false); 
  const [fileUploadFieldKey, setFileUploadFieldKey] = useState(Math.random());
  const [dataGridRows, setDataGridRows] = useState([]);
  const [carouselData, setCarouselData] = useState([]); // For storing carousel images
  const [errorFetchMessage, setErrorFetchMessage] = useState(null);
  const carouselRef = useRef(null);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  // Function to load previously classified data
  const loadPreviouslyClassifiedData = async () => {
    setLoading(true);
    try {
      const response = await fetch(getFullApiUrl(LOAD_SCREENSHOT), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          request_data: { campaign_id: campaignId },
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      setDataGridRows(result.data); // Update the DataGrid with the fetched data
      setCarouselData(result.data); // Store the data for the carousel
    } catch (error) {
      console.error("Error loading previous data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchFileList = async () => {
    const response = await fetch(getFullApiUrl(GET_ANALYZED_CLIENT_DATA_FILES), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        request_data: {
          campaign_id: campaignId,
        },
      }),
    });

    const data = await response.json();
    if (data.error) {
      throw new Error(data.error);
    }
    return data.files_by_client_id; // Assuming files are grouped by client ID.
  };

  // Function to fetch filtered paragraphs based on similarity score range
  const getIdentifiedParagraphsDataFiltered = async () => {
    setLoading(true);
    try {
      // Step 1: Fetch the file list first
      const fileList = await fetchFileList(); // Assuming fetchFileList() fetches file list

      const filePromises = Object.keys(fileList).flatMap((clientId) =>
        fileList[clientId].map((file) =>
          fetch(LAMBDA_FUNCTION_TO_GET_FILTERED_IDENTIFIY_PARAGRAPH, {
            method: "POST",
            body: JSON.stringify({
              campaign_id: campaignId,
              bucket_name: BUCKET_NAME,
              score_from: parseInt(filterSimilarityScore.from),
              score_to: parseInt(filterSimilarityScore.to),
              file_name: `${clientId}/${file}`,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())
            .then((inputData) => {
              const filteredData = inputData.filtered_analyzed_data || [];
              return filteredData.flatMap((entry) =>
                entry.blog_urls.map((blog, index) => ({
                  search_result_id: index + 1,
                  client_url: entry.client_url,
                  blog_url: blog.blog_url,
                  blog_title: blog.title || "",
                  keywords: blog.keywords || [],
                  selected: blog.selected || false,
                  similarity_score: blog.similarity_score || 0,
                }))
              );
            })
            .catch((error) => {
              console.error("Error fetching file data:", error);
              return [];
            })
        )
      );

      // Wait for all file data to be fetched and processed
      const allData = await Promise.all(filePromises);
      const finalData = allData.flat();
      setDataGridRows(finalData); // Update DataGrid with the filtered data
      setErrorFetchMessage(null);
    } catch (error) {
      console.error("Error:", error);
      setErrorFetchMessage("There is no data to classify");
    } finally {
      setLoading(false);
    }
  };

  // Function to handle file upload
  const uploadUserUploadedFile = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      // Implement your file parsing logic here
      // Set data to DataGrid (this needs to be implemented based on your logic)
      setDataGridRows(uploadedFile); 
    }
    setFileUploadFieldKey(Math.random()); // Reset the file input field
  };

  return (
    <Container>
      <Grid
        container
        justifyContent="center"
        spacing={2}
        style={{ marginBottom: "20px" }}
      >
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowCarousel(false)}
            disabled={!showCarousel} // Disable when already in table view
          >
            📊 Back to Table View
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowCarousel(true)}
            disabled={showCarousel} // Disable when already in carousel view
          >
            🎠 Start Carousel View
          </Button>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            align="center"
            style={{ marginTop: "10px" }}
          >
            Use the buttons above to switch between views. Click "Start" to see
            the carousel of blog screenshots! Use 'j' for previous and 'l' for
            next.
          </Typography>
        </Grid>
      </Grid>
      {!showCarousel ? (
        
        // DataGrid Table Section
        <Paper elevation={3} style={{ marginBottom: "20px" }}>
          <TabContext value={value}>
          <TabList onChange={handleTabChange} aria-label="Tab example">
            <Tab
              component="div"
              label={<div className="classify_tab_label_text">Previously Classified data</div>}
              value="1"
            />
            <Tab
              component="div"
              label={<div className="classify_tab_label_text">Classify on existing data</div>}
              value="2"
            />
            <Tab
              component="div"
              label={<div className="classify_tab_label_text">Classify on Uploaded data</div>}
              value="3"
            />
          </TabList>

        <TabPanel value="1">
          <div style={{ margin: "auto", width: "fit-content" }}>
            <Button
              component="label"
              variant="contained"
              endIcon={<DownloadIcon />}
              onClick={loadPreviouslyClassifiedData}
              disabled={loading}
            >
              Load
            </Button>
          </div>
        </TabPanel>

        <TabPanel value="2">
          I want to classify blog URLs with a similarity score between{" "}
          <TextField
            id="outlined-number-from"
            type="number"
            variant="standard"
            value={filterSimilarityScore.from}
            onChange={(event) => {
              const inputValue = event.target.value;
              if (inputValue >= 0 && inputValue <= filterSimilarityScore.to) {
                setFilterSimilarityScore({
                  from: inputValue,
                  to: filterSimilarityScore.to,
                });
              }
            }}
            sx={{ width: "35px" }}
          />{" "}
          and{" "}
          <TextField
            id="outlined-number-to"
            type="number"
            variant="standard"
            value={filterSimilarityScore.to}
            onChange={(event) => {
              const inputValue = event.target.value;
              if (inputValue >= filterSimilarityScore.from && inputValue <= 100) {
                setFilterSimilarityScore({
                  from: filterSimilarityScore.from,
                  to: inputValue,
                });
              }
            }}
            sx={{ width: "42px", ml: 1 }}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            sx={{ marginRight: "10px" }}
            variant="text"
            color="secondary"
            size="small"
            onClick={getIdentifiedParagraphsDataFiltered}
            disabled={loading}
          >
            Load the data
          </Button>
        </TabPanel>

        <TabPanel value="3">
          <div className="fileUploadContainer">
            <Button
              className="fileUploadButton"
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Upload file
              <Input
                type="file"
                id="file-input"
                onChange={uploadUserUploadedFile}
                key={fileUploadFieldKey}
                style={{
                  clip: "rect(0 0 0 0)",
                  clipPath: "inset(50%)",
                  height: 1,
                  overflow: "hidden",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  whiteSpace: "nowrap",
                  width: 1,
                }}
              />
            </Button>
          </div>
        </TabPanel>
      </TabContext>

      {/* DataGrid to display fetched data */}
      {loading ? (
        <CircularProgress />
      ) : (
        <Paper elevation={3} sx={{ mt: 3 }}>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={dataGridRows}
              columns={[
                { field: "search_result_id", headerName: "search_result_id", width: 150 },
                { field: "client_url", headerName: "Client URL", width: 200 },
                { field: "blog_url", headerName: "Blog URL", width: 200 },
                { field: "blog_title", headerName: "Blog Title", width: 150 },
                { field: "similarity_score", headerName: "Similarity Score", width: 130 },
                { field: "selected", headerName: "Selected", width: 100, type: "boolean" },
              ]}
              pageSize={5}
            />
          </div>
        </Paper>
      )}
        </Paper>
      ) : (
        // Carousel Section
        <Paper elevation={3} >
          <Typography variant="h6" align="center" gutterBottom>
            Blog Screenshots Carousel (Use 'j' for Left and 'l' for Right)
          </Typography>
          <div style={{
            display:'flex',
            gap:"5px",
            justifyContent:"right"
          }}>
            <Button variant="contained"
            color="primary">Good</Button>
            <Button variant="contained"
            color="secondary">Bad</Button>
            <Button variant="contained"
            color="secondary">Spam</Button>
          </div>
          <Carousel
            ref={carouselRef} // Attach ref to the carousel
            autoPlay={false} // Disable autoPlay for manual control
            indicators={true}
          >
            {dataGridRows.map((item) => (
              <Paper key={item.search_result_id} style={{ padding: "20px" }}>
                {item.screenshot ? (
                  <img
                    src={item.screenshot}
                    alt={item.blog_url}
                    style={{ width: "100%", height: "auto" }}
                  />
                ) : (
                  <Typography variant="body2" align="center">
                    Screenshot not available
                  </Typography>
                )}
                <Typography variant="h6">{item.page_type}</Typography>
                <Typography variant="body1">
                  Blog URL:{" "}
                  <a
                    href={item.blog_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.blog_url}
                  </a>
                </Typography>
                <Typography variant="body1">
                  Client URL:{" "}
                  <a
                    href={item.client_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.client_url}
                  </a>
                </Typography>
                <Typography variant="body1">
                  Similarity Score: {item.similarity_score}
                </Typography>
              </Paper>
            ))}
          </Carousel>
        </Paper>
      )}

      {/* Tabs and Data Handling */}
      
    </Container>
  );
};

export default PreviousClassifiedDataWithScreenshot;
