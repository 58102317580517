export const BUCKET_NAME = 'linkomatic-production'
export const ACCESS_KEY = 'AKIAWBFPGA22THKNPXTI'
export const SECRET_KEY = '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'


// const BASE_API_URL = 'http://3.19.0214.59:8005'; // server/

const BASE_API_URL = 'https://linkomatic.ai';
// const BASE_API_URL = 'http://192.168.0.197:8005';
// export const BASE_API_URL_MAIN = 'http://localhost:8005'; // localhost

// export const BASE_API_URL_MAIN = "http://s3.19.214.59:8001"; // server
const BASE_API_URL_FOR_CAMPAIGN = 'http://localhost:8006'; // localhost
const BASE_API_URL_FOR_BULK_PROCESS = 'http://localhost:8007'; // localhost
const BASE_API_URL_FOR_SAVING_SCRAPE_DATA = 'http://localhost:8008'; // localhost

export const LOGIN_API = '/api/login';
export const LOGIN_WITH_PASSCODE_API = '/api/login_with_passcode';
export const REGISTRATION_API = '/api/registration';
export const VERIFY_EMAIL = '/api/verify_email';

export const GET_ALL_USERS = '/api/admin/get_all_users'
export const ADD_NEW_USER = '/api/admin/add_new_user'
export const UPDATE_USER = '/api/admin/update_user'
export const DELETE_USER = '/api/admin/delete_user'
export const UPDATE_PASSWORD = '/api/admin/update_password'
export const UPDATE_USER_ROLE = '/api/admin/update_user_role'
export const UPDATE_USER_SUBSCRIPTION = '/api/admin/update_user_subscription'
export const UPDATE_USER_CREDIT_POINTS = '/api/admin/update_user_credit_points'
export const UPDATE_EMAIL_OF_USER = '/api/admin/update_email_of_user'
export const UPDATE_USER_NAME = '/api/admin/update_user_name'
export const GET_USER_INFO_BY_ID = '/api/get_user_info_by_id'
export const GET_USER_DETAILS = '/api/get_user_details_by_id'
export const GET_USER_NAME_BY_ID = '/api/get_user_name_by_id'
export const UPLOAD_USER_IMAGE = '/api/admin/upload_image_of_user'
export const UPDATE_CREDIT_POINTS_OF_USER = '/api/update_credit_points'
export const GET_THRESHOLD_VALUE = '/api/get_threshold_value'


export const  UPDATE_THRESHOLD_VALUE = '/api/update_threshold_value'
export const  UPDATE_PASSCODE = '/api/update_passcode'
export const  GET_PASSCODE = '/api/get_passcode'
export const UPDATE_PASSCODE_BY_ID = '/api/update_passcode_by_user_id'
export const UPDATE_PASSWORD_BY_ID = '/api/update_password_by_user_id'



export const GET_CREDIT_POINTS_OF_USER = '/api/get_credit_points_by_user_id'
export const GET_USER_ID_BY_NAME = '/api/get_user_id_by_name'



export const GET_ALL_WORKSPACE = '/api/get_all_workspaces'
export const GET_ALL_WORKSPACE_BY_USER = '/api/get_all_workspaces_of_user'

export const ADD_NEW_WORKSPACE = '/api/add_new_workspace'
export const UPDATE_WORKSPACE = '/api/update_workspace'
export const DELETE_WORKSPACE = '/api/delete_workspace'
export const UPDATE_USER_OF_WORKSPACE = '/api/update_user_of_workspace'
export const UPDATE_TEAM_OF_WORKSPACE = '/api/update_team_of_workspace'
export const GET_TEAM_ID_FROM_WORKSPACE = '/api/get_team_id_from_workspace'
export const GET_WORKSPACE_DETAILS = '/api/get_workspace_details'
export const CHECK_WORKSPACE_ACCESS = '/api/check_workspace_access'


export const ADD_WORKSPACE_MEMBER = '/api/add_workspace_member'
export const UPDATE_WORKSPACE_MEMBER = '/api/update_user_of_workspace'
export const GET_WORKSPACE_MEMBER = '/api/get_all_workspace_members'
export const DELETE_WORKSPACE_MEMBER = '/api/delete_workspace_member'
export const ADD_WORKSPACE_MEMBER_BY_MEMBER = '/api/add_workspace_member_by_member';



export const GET_ALL_ROLES = '/api/get_all_roles'
export const ADD_NEW_ROLES = '/api/add_new_role'
export const UPDATE_ROLES = '/api/update_role'
export const DELETE_ROLES = '/api/delete_role'


export const GET_ALL_SUBSCRIPTION = '/api/admin/get_all_subscriptions'
export const ADD_NEW_SUBSCRIPTION = '/api/admin/add_new_subscription'
export const UPDATE_SUBSCRIPTION = '/api/admin/update_subscription'
export const DELETE_SUBSCRIPTION = '/api/admin/delete_subscription'


export const GET_ALL_CAMPAIGNS = '/api/admin/get_all_campaigns'
export const ADD_NEW_CAMPAIGN = '/api/admin/add_new_campaign'
export const UPDATE_CAMPAIGN = '/api/admin/update_campaign'
export const DELETE_CAMPAIGN = '/api/admin/delete_campaign'
export const GET_CAMPAIGNS_OF_USER = '/api/get_campaign_details_by_user_id'
export const GET_CAMPAIGNS_DETAILS_OF_USER = '/api/get_campaign_detail_by_user_and_campaign_id'





export const GET_ALL_CREDIT_POINTS_DETAILS = '/api/admin/get_all_credit_points_holders_details'
export const GET_CREDIT_POINTS_DETAILS_OF_USER = '/api/get_credits_record_of_user'
export const ADD_NEW_CREDIT_POINTS_DETAIL = '/api/admin/add_new_credit_point'
export const ADD_NEW_CREDIT_POINTS_DETAILS_BY_MEMBER = '/api/admin/add_credit_points_by_member'
export const UPDATE_CREDIT_POINTS_DETAILS = '/api/admin/update_campaign'
export const DELETE_CREDIT_POINTS_DETAILS = '/api/admin/delete_campaign'
export const GET_CREDIT_RECORDS_OF_USER = '/api/get_credit_records_of_user_by_name'


export const GET_CREDIT_RECORDS_OF_TODAY = '/api/get_credit_points_used_today'
export const GET_CREDIT_RECORDS_OF_THIS_WEEK = '/api/get_credit_points_used_this_week'
export const GET_CREDIT_RECORDS_OF_THIS_MONTH = '/api/get_credit_points_used_this_month'




export const GET_ALL_TEAM = '/api/admin/get_all_teams'
export const GET_ALL_TEAM_DATA = '/api/get_unique_team_ids_and_names'
export const ADD_NEW_TEAM = '/api/admin/add_new_team'
export const UPDATE_TEAM = '/api/admin/update_team'
export const DELETE_TEAM = '/api/admin/delete_team'
export const TEAM_NAME_FROM_ID = '/api/find_team_name_by_id'
export const GET_TEAM_DETAILS = '/api/get_team_details'
export const CHECK_TEAM_ACCESS = '/api/check_team_access'





export const GET_ALL_TEAM_MEMBERS = '/api/admin/get_all_team_members'
export const GET_ALL_TEAM_MEMBERS_NOT_IN_TEAM = '/api/get_team_member_details_not_in_team'
export const GET_TEAM_MEMBER_DESIGNATION = '/api/get_member_designation'
export const GET_TEAM_MEMBER_DETAILS = '/api/get_team_member_details'
export const ADD_NEW_TEAM_MEMBER = '/api/admin/add_new_team_member'
export const ADD_NEW_TEAM_MEMBER_FOR_MEMEBR = '/api/add_new_team_member_for_member'
export const UPDATE_TEAM_MEMBER = '/api/admin/update_team_member'
export const UPDATE_TEAM_MEMBER_DESIGNATION = '/api/update_member_designation'
export const GET_ALL_TEAMS_OF_USER = '/api/get_teams_by_member_id'
export const UPDATE_CREDIT_POINTS_WEEK_LIMIT = '/api/update_credit_points_week_limit'


export const DELETE_TEAM_MEMBER = '/api/delete_team_member_and_associated_workspaces'

export const GET_TEAM_MEMBER_DETAILS_OF_TEAM = '/api/get_all_team_members_details_of_team'
export const GET_WORKSPACES_OF_TEAM_MEMBER = '/api/get_workspaces_for_team_member'
export const GET_TEAM_MEMBER_DETAILS_TO_SEARCH_IN_TEAM = '/api/get_all_team_members_details_to_search_in_teams'

export const DELETE_ALL_USERS_DATA = '/admin/api/delete_all_users'
export const DELETE_ALL_DATA = '/admin/api/delete_all_data'

export const GET_ALL_TEAM_BY_OWNER_ID = '/api/get_teams_by_owner'
export const ADD_NEW_TEAM_BY_OWNER_ID = '/api/add_new_team_by_owner_id'
export const DELETE_ALL_TEAMS_DATA = '/api/admin/delete_all_teams'
export const GET_WORKSPACE_MEMBER_BY_USER = '/api/get_all_workspaces_of_user'
export const GET_WORKSPACE_MEMBER_BY_TEAM_MEMBER = '/api/get_workspace_member_by_team_member_id'
export const GET_WORKSPACE_MEMBER_BY_WORKSPACE_ID = '/api/get_workspace_members_by_workspace_id'
export const ADD_NEW_WORKSPACE_MEMBER_BY_USER = '/api/add_new_workspaces_of_user'
export const GET_TEAM_BY_WORKSPACE = '/api/get_team_by_workspace_id'
export const SEARCH_WORKSPACE_TO_ADD_CAMPAIGN = '/api/get_workspace_for_search_to_add_campaign'
export const DELETE_ALL_TEAM_MEMBERS = '/api/admin/delete_all_team_members'

export const DELETE_ALL_WORKSPACE_DATA = '/api/delete_all_workspace'

export const GET_ALL_TEAM_MEMBERS_OF_TEAMS = '/api/admin/get_all_team_members_of_teams'

export const GET_ALL_CAMPAIGNS_BY_WORKSPACE_ID = '/api/get_all_campaigns_by_workspace'

export const GET_ALL_WORKSPACE_BY_TEAM = '/api/get_all_workspaces_of_team'
export const GET_ALL_WORKSPACE_BY_TEAM_ID = '/api/get_all_workspaces_by_team'

export const GET_TEAM_NAME_FROM_WORKSPACE = '/api/get_team_name_from_workspace'
export const GET_WORKSPACE_NAME_BY_ID = '/api/get_workspace_name_by_id'
export const GET_CAMPAIGN_NAME_BY_ID = '/api/get_campaign_name_by_id'
export const CHECK_USER_CAMPAIGN_ACCESS = '/api/check_user_campaign_access'


export const UPDATE_USER_INFO_BY_ID = '/api/update_user_info_by_id'
export const UPDATE_USED_CREDIT_POINTS = '/api/update_used_credit_point_of_team_member'
export const GET_ALL_CAMPAIGNS_OF_USER = '/api/'
export const GET_ID_BY_CAMPAIGNS_NAME = '/api/get_ids_by_campaign_name'
export const UPDATE_CREDIT_POINT_OF_DEDUCTION = '/api/update_credit_point_of_deduction'
export const GET_POINTS_DETAILS = '/api/get_points_details'
export const GET_INDIVIDUAL_CAMPAIGN_DETAIL = '/api/get_individual_campaign_detail'
export const RECENTLY_USED_CAMPAIGNS  = '/api/recently_used_campaigns'

//  Ticket
export const GET_ALL_TICKET = '/api/get_all_tickets'
export const ADD_NEW_TICKET = '/api/add_new_ticket'
export const GET_ALL_TICKETS_OF_USER = '/api/get_all_tickets_by_user_id'
export const GET_TICKET_BY_ID = '/api/get_ticket_by_id'

//  point

export const GET_ALL_POINTS = '/api/admin/get_all_points'
export const GET_CREDIT_POINTS_BY_BUTTON_NAME = '/api/get_point_by_button_name'
export const ADD_POINT = '/api/admin/add_point'
export const UPDATE_POINT = '/api/admin/update_point'
export const CHECK_CREDIT_CONDITION = "/api/check_credit_conditions"
export const GET_CREDIT_RECORD = '/api/get_credit_record_details'




export const GET_CAMPAIGN_FROM_S3 = '/api/get_campaigns_list_from_s3'
export const LOAD_CAMPAIGN_FROM_S3 = "/api/load_campaign_from_s3"
export const GET_LINKS_FROM_PAGE = "/api/get_links_from_page"
export const SAVE_CAMPAIGN_FROM_S3 = "/api/save_campaign_to_s3"
export const SCRAPE_URLS = "/api/scrape_urls"

export const GET_CAMPAIGN_DATA = "/api/get_campaign_data"
export const UPDATE_INDIVIDUAL_CAMPAIGN_DATA = '/api/update_campaign_individual_fields'

export const GET_CLIENT_URLS_DATA = '/api/get_client_urls_data'
export const SAVE_CLIENT_URLS_DATA = '/api/save_client_urls_data'

export const FIND_AUTOMATIC_KEYWORDS = '/api/find_automatic_keywords'
export const GET_BLOG_URLS_LENGTH = '/api/get_blog_url_data_length'
export const GET_BLOG_URLS_LIST = '/api/get_blog_urls_list'
export const GET_CLIENT_URLS_LIST = '/api/get_client_urls_list'

export const GET_BLOG_URLS_DATA = '/api/get_blog_urls_data'
export const GET_SEARCH_QUERY_DATA = '/api/get_search_query_form_data'
export const SAVE_SEARCH_QUERY_DATA = '/api/save_search_query_form_data'
export const GET_BLACK_LIST_DATA_OF_CAMPAIGN = '/api/get_black_list_data_of_campaign'
export const GET_BLACK_LIST_DATA_OF_WORKSPACE = '/api/get_black_list_data_of_workspace'
export const SAVE_BLOG_URLS_DATA = '/api/save_blog_urls_data'
export const SAVE_BLACK_LIST_DATA_LIST_OF_CAMPAIGN = '/api/save_black_list_data_list_of_campaign'
export const SAVE_BLACK_LIST_DATA_LIST_OF_WORKSPACE = '/api/save_black_list_data_list_of_workspace'
export const DOWNLOAD_ANALYZED_BLOG_DATA = '/api/download_analyzed_blog_data'
export const GET_SCRAPPED_DATA = '/api/get_scraped_data'
export const DELETE_BLOG_URLS_DATA = '/api/delete_blog_urls_data'
export const DELETE_ANALYZED_UPLOAD_IDENTIFIED_PARAGRAPH_DATA = '/api/delete_user_upload_identified_paragraphs_data'



export const DOWNLOAD_ANALYZED_BLOG_DATA_BY_CLIENT_URLS = '/api/download_analyzed_blog_data_by_client_urls'

export const IDENTIFY_PARAGRAPH = '/api/identify_paragraph'
export const GET_PREVIOUSLY_IDENTIFIED_PARAGRAPH = '/api/get_previously_identified_Paragraph'
export const GET_IDENTIFIED_PARAGRAPHS_DATA = '/api/get_identified_paragraphs_data'
export const SAVE_IDENTIFIED_PARAGRAPHS_DATA = '/api/save_identified_paragraphs_data'
export const USER_UPLOAD_IDENTIFIED_PARAGRAPHS_DATA = '/api/user_upload_identified_paragraphs_data'
export const USER_UPLOAD_FIND_CONTACT_FROM_EXCEL_DATA = '/api/user_upload_find_contact_from_excel_data'
export const USER_UPLOAD_FIND_CONTACT_FROM_LINK_DATA = '/api/user_upload_find_contact_from_link_data'
export const GET_FIND_CONTACT_FROM_LINK_DATA = '/api/get_find_contact_from_link_data'
export const GET_FIND_CONTACT_FROM_EXCEL_DATA = '/api/get_find_contact_from_excel_data'
export const CLEAR_FIND_CONTACT_FROM_LINK_DATA = '/api/clear_find_contact_from_link_data'
export const CLEAR_FIND_CONTACT_FROM_EXCEL_DATA = '/api/clear_find_contact_from_excel_data'
export const LOAD_PREVIOUSLY_CLASSIFIED_DATA = '/api/load_previously_classified_data'
export const SAVE_SIMILARITY_SCORE = '/api/save_similarity_score'
export const CALCULATE_SIMILARITY_SCORE_FOR_ALL = '/api/calculate_similarity_score_for_all'
export const SUGGEST_LINK_INSERTION = '/api/suggest_link_insertion'
export const GET_IDENTIFIED_PARAGRAPHS_DATA_FILTERED = '/api/get_identified_paragraphs_data_filtered'
export const GET_ANALYZED_CLIENT_DATA_FILES = '/api/get_analyzed_client_data_files'
export const GET_USER_UPLOAD_IDENTIFIED_PARAGRAPH_FILES = '/api/get_user_upload_identified_paragraphs_files'
export const SAVE_BLOG_SCRAPE_DATA = '/api/save_scraped_data'
export const DELETE_ANALYZED_CLIENT_URL = '/api/delete_analyzed_client_url_file'
export const LOAD_SCREENSHOT = '/api/load_previously_classified_data_with_screenshot'

export const PROCESS_SELECTED_CLIENTS = '/process_selected_clients'

export const SCRAPE_WEBSITE_TO_FIND_AUTHOR = '/api/scrape_website_to_find_author'



export const getFullApiUrl = (endpoint) => `${BASE_API_URL}${endpoint}`;
export const getFullApiUrlForCampaign = (endpoint) => `${BASE_API_URL_FOR_CAMPAIGN}${endpoint}`;
export const getFullApiUrlForBulkProcess = (endpoint) => `${BASE_API_URL_FOR_BULK_PROCESS}${endpoint}`;
export const getFullApiUrlForSaveScrapeData = (endpoint) => `${BASE_API_URL_FOR_SAVING_SCRAPE_DATA}${endpoint}`;


export const GOOGLE_CLIENT_ID = '98987329460-tjp13h9vedbumrer4fuml4nlugkfla7k.apps.googleusercontent.com';
export const GOOGLE_CLIENT_SECRET = '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';
